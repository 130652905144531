<template>


    <!-- BEGIN: Content-->
    <div class="content-body">
        <!-- full job description -->
        <!-- Bordered table start -->
        <div class="row" id="table-bordered" v-if="existingData">
            <div class="col-12">
                <base-card-plain :showFooter="false" v-if="existingData && jdList.length > 0">
                    <template #header>
                        <div class="col-3 col-md-3 col-lg-3 col-sm-12 mb-1">
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="searchValue"
                                       placeholder="Search by title or category"
                                       aria-describedby="button-addon2" />
                            </div>
                        </div>
                        <base-card-drop-down>
                            <template #title>
                                JD options
                            </template>
                            <template #default>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-check bs-icon-tiny" ></i>
                                    Sort JD by Date
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-x bs-icon-tiny" ></i>
                                    Sort JD by title
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-x bs-icon-tiny" ></i>
                                    Sort JD by category
                                </a>
                            </template>
                        </base-card-drop-down>
                    </template>
                    <template #default>

                            <table class="table table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th>JD title</th>
                                        <th>Category</th>
                                        <th class="tiny-td-col">Date</th>
                                        <th class="tiny-td-col">Status</th>
                                        <th class="tiny-td-col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr class="table-hover" v-for="jd in activeJDList" :key="jd.id">
                                        <td>
                                            <span class="fw-bold">{{ jd.title }}</span>
                                        </td>
                                        <td>{{ jd.category }}</td>
                                        <td>{{ new Date(jd.created_date).toLocaleDateString("en-US") }}</td>
                                        <td>
                                            <span
                                                class="badge rounded-pill badge-light-primary me-1"
                                                :class="jd.status == 'active'?'badge-light-primary':'badge-light-danger'"
                                            >
                                                {{ jd.status }}
                                            </span>
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots bs-icon-small" ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                                        <span> Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-archive bs-icon-small" ></i>
                                                        <span> Archive</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-trash bs-icon-small" ></i>
                                                        <span> Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <base-pagination>
                            </base-pagination>
                    </template>
                </base-card-plain>
                <base-card-response class="mt-1" cardType="secondary" v-else>

                    <template #header></template>
                    <template #default>
                        <p>
                            You currently have no job descriptions saved.
                            <router-link to="/recruitment_create_jd" class="text-white" >
                                <u>Create a new JD </u>
                            </router-link>
                        </p>
                    </template>

                </base-card-response>
            </div>
        </div>

        <base-card-response class="mt-1" cardType="secondary" v-if="!existingData">

            <template #header></template>
            <template #default>
                <p>
                    {{reqError}}
                </p>
            </template>

        </base-card-response>
        <!-- Bordered table end -->
        <!-- full job description -->



    </div>
    <!-- END: Content-->


</template>


<script>
// import the core functions from vuex
import { ref,inject,computed } from 'vue';
// import { useStore } from 'vuex';



export default{
    setup(){


        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');


        const existingData = ref(false);
        const reqError = ref("Loading JD list ....");

        const jdList = ref([]);


            // use axios to get the data
            axios.get(
                coreUrl.backendUrl+'get_job_description_list',
                {withCredentials: true}).then((response) =>{

                existingData.value = true;
                const result = response.data.data;
                jdList.value = result;

            }).catch((error)=>{
                    let errorMsg = error.response.data.message.req_msg
                    reqError.value = errorMsg?errorMsg:"Your JDs could not be retrieved at this time";
                    existingData.value = false;
            })



        const searchValue = ref("");

        // computed value for JD list
        const activeJDList = computed(function(){

            let currJDList= jdList.value;

            if(searchValue.value){
                for(let i=0; i < currJDList.length; i++){
                    currJDList = currJDList.filter(item => item.title.toLowerCase().search(searchValue.value.toLowerCase()) >= 0  ||
                                                   item.category.toLowerCase().search(searchValue.value.toLowerCase()) >= 0  );
                }
            }

            return currJDList;

        });


        return {
            jdList,
            searchValue,
            activeJDList,
            existingData,
            reqError
        }

    }
}


</script>
